import { createApp } from 'vue';
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import VueGtag from "vue-gtag";
import { VueTolgee } from '@tolgee/vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import router from './router/router.js';

import './style.css'
import App from './App.vue'
import { createTolgee, tolgeeFormatPlugin } from './helpers/useTolgee.js';

const tolgee = createTolgee();
const pinia = createPinia()
const app = createApp(App);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/portal.dubrink.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.use(PrimeVue);
app.use(ToastService); 
app.use(ConfirmationService);
app.use(autoAnimatePlugin);
app.use(router);
app.use(pinia);
app.use(VueGtag, { config: { id: import.meta.env.VITE_GA_MEASUREMENT_ID } }, router);
app.use(VueTolgee, { tolgee });
app.use(tolgeeFormatPlugin, { allowedTags: ["b", "i"] })
app.directive('tooltip', Tooltip);
app.mount('#app');