import { createWebHistory, createRouter } from 'vue-router'
import routes from './routes.js';
import { useUserStore } from '@/stores/user.store.js';
import { getProfile } from "@/services/Auth.service.js";

const router = createRouter({
    history: createWebHistory(),
    routes,
});

/**
 * Allows access based on the userLevel and pageLevel or forces the user to an interstitial
 * @param {number} userLevel access level of the users
 * @param {number} pageLevel required access level for the page
 * @returns 
 */
const ensureDestinationPostLogin = (toComponentName, user) => {
    // Company not yet created => Force registration
    if(!user?.company) {
        return "/signup-completion";
    }
    // // // Sending unsubscribed importers that manage their own billing to the checkout
    if(toComponentName !== "WaitingList" && user?.company?.type === 1 && user?.company?.billingType === 1 && user?.company?.billingPlan === "FREE") {
        return "/checkout";
    }
    // Sending blocked users to a waiting list sink hole
    if(user?.blocked) {
        return "/waiting-list"
    }
    return;
}


router.beforeEach( async (to) => {
    try {
        const publicPages = ["/login", "/register", "/maintenance"];
        const isPublicPage = publicPages.includes(to.path); 
        if(isPublicPage) {
            return;
        }
        const userStore = useUserStore();
        if(!userStore.isLoggedIn) {
            const { data } = await getProfile();
            userStore.setUser(data);
        }
        // Between pages => Validate if we're logged in
        const redirectPath = ensureDestinationPostLogin(to.name, userStore.user);
        if (redirectPath && redirectPath !== to.path) {
            return redirectPath;
        }
        return;
    } catch (error) {
        if(!error.status) {
            return { 
                path: "/maintenance",
                query: {
                    force: true,
                }
            };
        }
        if(error.status === 401 && to.path !== "/login") {
            return "/login";
        }
        return;
    }
});
export default router;