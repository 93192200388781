import { useToast } from "primevue/usetoast";
import { useUserStore } from "@/stores/user.store.js";
import RedirectView from "../pages/RedirectView.vue";

const routes = [
    {
        path: "/",
        redirect: '/home' 
    },
    { 
        path: "/login", 
        alias: ["/signin", "/sign-in"],
        name: "Login",
        component: RedirectView,
        beforeEnter: () => {
            window.location.href = `${ import.meta.env.VITE_API_URL }/auth/login`;
        },
    },
    { 
        path: "/register", 
        alias: ["/signup", "/sign-up"],
        name: "Register",
        component: RedirectView,
        beforeEnter: () => {
            window.location.href = `${ import.meta.env.VITE_API_URL }/auth/register`;
        },
    },
    { 
        path: '/signup-completion', 
        name: "SignupCompletion",
        component: () => import("@/pages/SignupCompletion/SignupCompletion.vue"),
        meta: {
            private: false,
        },
    },
    { 
        path: '/waiting-list', 
        name: "WaitingList",
        component: () => import("@/pages/SignupCompletion/WaitingList.vue"),
        meta: {
            private: true,
        },
    },
    {
        path: "/checkout",
        children: [
            { 
                path: '', 
                name: "Checkout",
                component: () => import("@/pages/Checkout/StartCheckout.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/profile', 
        name: "Profile",
        meta: {
            private: true,
        },
        component: () => import("@/pages/ProfileView.vue"),
    },
    { 
        path: '/home', 
        name: "Landing",
        component: () => import("@/pages/LandingView.vue"),
        meta: {
            private: true,
        }
    },
    { 
        path: '/suppliers', 
        children: [
            {
                path: '', 
                name: "SupplierList",
                component: () => import("@/pages/Suppliers/SupplierListView.vue"),
                meta: {
                    private: true,
                },
            }, 
            { 
                path: 'new', 
                name: "SupplierEdit",
                component: () => import("@/pages/Suppliers/SupplierListView.vue"),
                meta: {
                    private: true,
                },
            },
        ]
    },
    { 
        path: '/attachments', 
        name: "AttachmentList",
        component: () => import("@/pages/Attachments/AttachmentListView.vue"),
        meta: {
            private: true,
        },
    },
    { 
        path: '/importers', 
        children: [
            { 
                path: '', 
                name: "ImporterList",
                component: () => import("@/pages/Importers/ImporterListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: 'new', 
                name: "ImporterEdit",
                component: () => import("@/pages/Importers/ImporterEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
        meta: {
            private: true,
        },
    },
    { 
        path: '/imports', 
        children: [
            { 
                path: '', 
                name: "ImportList",
                component: () => import("@/pages/Imports/ImportListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "ImportEdit",
                component: () => import("@/pages/Imports/ImportEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/import-lines', 
        children: [
            { 
                path: '', 
                name: "ImportLineList",
                component: () => import("@/pages/ImportLines/ImportLineListView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "ImportLineEdit",
                component: () => import("@/pages/ImportLines/ImportLineEditView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: 'uploader', 
                redirect: '/import-lines/uploader/new',
            },
            { 
                path: 'uploader/:cuid', 
                name: "ImportLineUploader",
                component: () => import("@/pages/ImportLines/ImportLineUploaderView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/uploads', 
        children: [
            { 
                path: '', 
                name: "UploadList",
                component: () => import("@/pages/Uploads/UploadListView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    {
        path: '/upload-maps', 
        children: [
            { 
                path: '', 
                name: "UploadMapList",
                component: () => import("@/pages/UploadMaps/UploadMapListView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "UploadMapEdit",
                component: () => import("@/pages/UploadMaps/UploadMapEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ]
    },
    { 
        path: '/installations', 
        children: [
            { 
                path: '', 
                name: "InstallationList",
                component: () => import("@/pages/Installations/InstallationListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "InstallationEdit",
                component: () => import("@/pages/Installations/InstallationEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/emissions', 
        children: [
            { 
                path: '', 
                name: "EmissionList",
                component: () => import("@/pages/Emissions/EmissionListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "EmissionEdit",
                component: () => import("@/pages/Emissions/EmissionEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    {
        path: "/onboarding",
        name: "Onboarding",
        component: () => import("@/pages/Uploads/OnboardingView.vue"),
        meta: {
            private: true,
        },
    },
    { 
        path: '/production-processes', 
        children: [
            { 
                path: '', 
                name: "ProductionProcessList",
                component: () => import("@/pages/ProductionProcesses/ProductionProcessListView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "ProductionProcessEdit",
                component: () => import("@/pages/ProductionProcesses/ProductionProcessEditView.vue"),
                meta: {
    
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/parameter-sets', 
        children: [
            { 
                path: '', 
                name: "ParameterSetList",
                component: () => import("@/pages/ParameterSets/ParameterSetListView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "ParameterSetEdit",
                component: () => import("@/pages/ParameterSets/ParameterSetEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/data-requests', 
        children: [
            { 
                path: '', 
                name: "DataRequestList",
                component: () => import("@/pages/DataRequests/DataRequestListView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "DataRequestEdit",
                component: () => import("@/pages/DataRequests/DataRequestEditView.vue"),
                meta: {
    
                    private: true,
                },
            },
        ]
    },
    { 
        path: '/process-records', 
        children: [
            { 
                path: '', 
                name: "ProcessRecordList",
                component: () => import("@/pages/ProcessRecords/ProcessRecordListView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "ProcessRecordEdit",
                component: () => import("@/pages/ProcessRecords/ProcessRecordEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ]
    },
    { 
        path: '/reports', 
        children: [
            { 
                path: '', 
                name: "XmlReportList",
                component: () => import("@/pages/Reports/ReportListView.vue"),
                meta: {
                    private: true,
                },
            },
            { 
                path: ':cuid', 
                name: "XmlReportEdit",
                component: () => import("@/pages/Reports/ReportEditView.vue"),
                meta: {
                    private: true,
                },
            },
        ],
    },
    { 
        path: '/settings', 
        component: () => import("@/pages/Settings/SettingsView.vue"),
        children: [
            {
                path: "",
                name: "SettingsEntry",
                redirect: "/settings/details"
            },
            { 
                path: 'details', 
                name: "SettingsDetails",
                component: () => import("@/pages/Settings/ManageCompanyDetails.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: 'team', 
                name: "SettingsTeam",
                component: () => import("@/pages/Settings/ManageTeamView.vue"),
                meta: {
    
                    private: true,
                },
            },
            { 
                path: 'billing', 
                name: "SettingsBilling", 
                component: () => import("@/pages/Settings/ManageBillingView.vue"),
                meta: {
    
                    private: true,
                },
            },
        ],
        meta: {
            private: true,
        },
    },
    // Maintenance page
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import("@/pages/MaintenanceView.vue"),
    },
    // 404 page
    {
        path: "/:pathMatch(.*)*",
        name: "404Handler",
        component: () => import("@/pages/NotFoundView.vue"),
    },
];

export default routes;