import { useFetch } from "@/helpers/useFetch.js";
const { request } = useFetch();

export const logout = () => {
    return request("POST", "/auth/logout");
};
export const completeRegistration = (payload) => {
    return request("POST", "/auth/register", payload);
};
export const getProfile = () => {
    return request("GET", `/auth/profile`);
};
export const updateProfile = (payload) => {
    return request("POST", `/auth/profile`, payload);
};
export const refreshProfile = () => {
    return request("POST", `/auth/refresh`);
};
export const getOrganizations = () => {
    return request("GET", `/auth/organization`);
};
export const switchOrganization = (payload) => {
    return request("POST", `/auth/organization`, payload);
};
